import React from 'react'
import Helmet from 'react-helmet'
import {Route} from 'react-router-dom'
import {BUSINESS_NAME} from 'config'


const CustomRoute = (props) => {
  const { component: Component, path, pageTitle, ...rest } = props;
  return  <>
    <Helmet>
      <title> {`${BUSINESS_NAME} ${pageTitle !== "" ? pageTitle : ""} - by popMenu`}</title>
    </Helmet>
    <Route
      {...rest}
      render={props => <Component {...props} pageTitle={pageTitle} />}
    />
  </>


}

export default CustomRoute