import {ReactComponent as appetizersIcon} from 'assets/icons/appetizers.svg'
import {ReactComponent as coffeeIcon} from 'assets/icons/coffee.svg'
import {ReactComponent as softDrinksIcon} from 'assets/icons/softdrink.svg'
import {ReactComponent as cocktailsIcon} from 'assets/icons/cocktail.svg'
import {ReactComponent as saladsIcon} from 'assets/icons/salad.svg'
import {ReactComponent as sandwichesIcon} from 'assets/icons/sandwich.svg'
import {ReactComponent as wineGlassIcon} from 'assets/icons/wineglass.svg'
import {ReactComponent as champagneIcon} from 'assets/icons/champagne.svg'
import {ReactComponent as whiskeyIcon} from 'assets/icons/whiskey.svg'
import {ReactComponent as beerIcon} from 'assets/icons/beer.svg'
import {ReactComponent as brunchIcon} from 'assets/icons/pancakes.svg'

export const categoryIcons = [
  {id:1,component:coffeeIcon},
  {id:2,component:softDrinksIcon},
  {id:3,component:cocktailsIcon},
  {id:4,component:saladsIcon},
  {id:5,component:sandwichesIcon},
  {id:6,component:wineGlassIcon},
  {id:7,component:champagneIcon},
  {id:8,component:whiskeyIcon},
  {id:9,component:beerIcon},
  {id:10,component:brunchIcon}
  ]