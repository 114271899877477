import React, {useEffect, useState} from 'react'
import {Box, Typography, Grid,Chip} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Helmet from 'react-helmet'
import {BUSINESS_NAME} from 'config'

const styles = theme => ({
  productBox: {
    userSelect:'none',
    padding: theme.spacing(2),
    backgroundColor:'#fffaf5',
    borderRadius:'5px',
    minHeight:'40px',
    marginBottom:theme.spacing(1),
    boxShadow: '1px 2px 4px 0px #decab5'
  },
  productsContainer:{
    marginBottom:theme.spacing(12)
  },
  productCategory:{
    userSelect:'none'
  },
  productPrice:{
    userSelect:'none',
    fontSize:'26px',
    color:'white',
    fontWeight:300
  }
})

const ProductsView = withStyles(styles)(({products, category, classes, ...props}) => {

  const [productsFilters, setProductsFiltered] = useState([])
  useEffect(() => {
    const productsByCategory = products.filter(function (obj) {
      return obj.categoryId === category.id
    })
    if (productsByCategory) {
      setProductsFiltered(productsByCategory)
    }
    window.scrollTo(0, 0)

  }, [category, products])

  return <>
    <Helmet>
      <title> {`${BUSINESS_NAME} | ${category.name ? category.name : ''} `}</title>
    </Helmet>
        <Box className={classes.productsContainer}>
        {productsFilters.map((product) => {
          return <Box key={product.productId} className={classes.productBox}>
            <Grid container direction={'row'} alignItems={'flex-start'} justify={'flex-start'}>
              <Grid item xs={9}>
                <Box mr={1}>
                <Typography variant={'h5'} color={'primary'}>{product.name}</Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box style={{textAlign:'right'}}>
                <Chip className={classes.productPrice} label={`${product.price}€`} color={'secondary'} variant={'default'} />
                </Box>
              </Grid>
              <Grid item xs={7}>
                <Typography variant={'subtitle2'} color={'secondary'}>{product.description}</Typography>
              </Grid>
            </Grid>
          </Box>
        })}
        </Box>
      </>
})


export default ProductsView