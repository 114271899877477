
import {createMuiTheme} from '@material-ui/core'

export const theme = createMuiTheme({
  fontFamily: [
    "'Karla', sans-serif",
    'Roboto',
    'Helvetica Neue',
    'Helvetica',
    '-apple-system',
    'Arial',
    'sans-serif'
  ].join(','),
  palette: {
    primary: {
      main: '#261111',
      light: '#e2cbc5'
    },
    secondary: {
      main: '#C69C6D',
      light:'#c69c6d4f'
    },
    footerButtons:{
      main:'#ededed'
    },
    background: {primary: '#885e64',secondary:'#503a3a'}
  },
  overrides: {
    MuiTypography:{
      root:{
        fontFamily: [
          'Source Sans Pro',
          'Roboto',
          'Helvetica Neue',
          'Helvetica',
          '-apple-system',
          'Arial',
          'sans-serif'
        ].join(',')
      },
      h1:{
        fontWeight:300
      },
      h2:{
        fontWeight:300
      },
      h3:{
        fontWeight:300
      },
      h4:{
        fontWeight:300
      },
      h5:{
        fontWeight:300
      },
      h6:{
        fontWeight:300
      }
    },
    MuiTab:{
      root:{
        width:'50px !important',
        minWidth:'50px !important',
        height:'50px !important',

          '&$indicator':{
            width:'20px'
          }

      },
      textColorPrimary:{
        color:'#885e64',
        '&$selected':{
          color:'white',
          backgroundColor:'#a59089'
        }
      },
    },
    MuiTabScrollButton:{
      root:{
        color:'#807c75'
      }
    },
    MuiButton: {
      root: {
        position: 'relative',
        borderRadius: '25px',
        textTransform: 'initial'
      },
      primary: {
        main: '#261111'
      },
      secondary: {
        main: '#C69C6D',
      },
      outlinedSecondary:{
        main: '#c3a482'
      },
      contained: {
        boxShadow: 'none !important'
      },
      containedSecondary: {
        color: 'white'
      }
    },
    MuiDrawer:{
      paper:{
        backgroundColor:'#ded2ca'
      }
    }
  }
  })