import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {ThemeProvider} from '@material-ui/core/styles'
import {theme} from 'theme'
import MainMenu from 'components/Menu/MainMenu'
import {useParams, Redirect} from 'react-router-dom'

const styles = theme => ({

})

const HomeMenu = withStyles(styles)(({classes, ...props}) => {
  const {lang} = useParams()
  return <>
    <ThemeProvider theme={theme}>
        <MainMenu lang={lang}/>
        {lang !== 'gr' && lang !== 'en' ? <Redirect to={'/'}/> : null}
    </ThemeProvider>
  </>
})

export default HomeMenu