import React, {useState, useEffect} from 'react'
import {SvgIcon} from '@material-ui/core'
import {categoryIcons} from 'components/core/categoryIcons'


const FooterMenuButton = ({id, ...props}) => {
  const [categoryIcon, setCategoryIcon] = useState(null)

  useEffect(() => {
    const iconFound = categoryIcons.filter(function (obj) {
      return obj.id === id
    }).shift()
    if (iconFound)
      setCategoryIcon(iconFound.component)
  }, [id])

  return (
    categoryIcon &&
    <SvgIcon component={categoryIcon} fontSize={'large'} viewBox={'0 0 512 512'} width={'20'} height={'20'} {...props}/>
  )
}

export default FooterMenuButton