export const translate = {
  categories:
    [
      {name: 'Appetizers',id:0,icon:0},
      {name: 'Salads',id:1,icon:1},
      {name: 'Seafood',id:2,icon:2},
      {name: 'Cooked',id:3,icon:3},
      {name: 'Fishes',id:4,icon:4},
    ],
  products:
    [
      {categoryId:0,productId:0,name:'Τυροκροκέτες',description:null,price:'4.00'},
      {categoryId:0,productId:1,name:'Τζατζίκι',description:null,price:'4.00'},
      {categoryId:0,productId:2,name:'Κολοκυθοκεφτέδες',description:'Κολοκύθι, γραβιέρα, αυγό',price:'5.00'},
      {categoryId:1,productId:3,name:'Χωριάτικη',description:'Ντομάτα, αγγούρι, φέτα, πιπεριά, κρεμμύδι',price:'8.00'},
      {categoryId:1,productId:4,name:'Σαλάτα Άννα',description:'Μαρούλι, λιαστές ντομάτες, φλούδα παρμεζανα, βαλσάμικο σιρόπι, κουκουνάρι',price:'9.00'},
    ]
}