import React, {useEffect, useState} from 'react'
import {Box, Container, Grid, Typography, Button, AppBar, Toolbar, SwipeableDrawer, Divider,DialogTitle,Dialog} from '@material-ui/core'
import {Facebook, Instagram, Menu} from '@material-ui/icons'
import Logo from 'assets/logo.png'
import {withStyles} from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import {translate as english} from 'components/Menu/lang/en'
import {translate as greek} from 'components/Menu/lang/gr'
import FooterMenuButton from 'components/core/FooterMenuButton'
import ProductsView from 'components/Menu/ProductsView'
import backgroundImage from 'assets/indexBack.jpg'
import {BUSINESS_NAME} from 'config'
import Flag from 'react-world-flags'

const styles = theme => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  backgroundContainer: {
    backgroundColor: '#f9f9f9',
    paddingBottom: theme.spacing(2),
    minHeight: window.innerHeight,
    height: 'auto'
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  businessName: {
    fontSize: '20px',
    fontWeight: 400,
    color: theme.palette.primary.main,
    textShadow: `-2px 2px 4px ${theme.palette.primary.light}`
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    padding: theme.spacing(1),
    backgroundColor: '#f9f9f9'
  },
  socialIcon: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  headerBar: {
    top: 0,
    width: '100%',
    margin: 0,
    padding: 0,
    background: `linear-gradient(0deg, rgba(38, 17, 17, 0.3), rgba(38, 17, 17, 0.3)), url(${backgroundImage}) no-repeat`,
    backgroundSize: 'cover',
    borderBottom: `2px solid #a58569`,
    paddingBottom: theme.spacing(2)
  },
  grow: {
    flexGrow: 1,
  },
  footerIcon: {
    width: '25px',
    textAlign: 'center',
    border: `1px solid ${theme.palette.secondary.main}`,
    marginRight: theme.spacing(2),
    borderRadius: '10px',
    backgroundColor: theme.palette.footerButtons.main,
    boxShadow: '1px 2px 4px 0px #decab5',
  },
  categoryRow: {
    userSelect: 'none',
    width: '100%',
    borderRadius: '10px 0 10px 0',
    backgroundColor: 'rgba(255,255,255,0.82)'
  },
  customIndicator: {
    width: 0,
    height: 0
  },
  drawerWidth: {
    width: '250px',
    height:'100%'
  },
  footerSocial:{
    position:'absolute',
    bottom:20,
    right:40
  }

})

const VromolithosPopup = ({openDialog}) =>{

  const [open,setOpen] = useState(true)


  const handleClose= () =>{
    setOpen(false)
    openDialog(false)
  }

  return <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
    <DialogTitle id="simple-dialog-title">Βρομόλιθος</DialogTitle>
    <Box p={3}>Η σημασία της λέξης Βρομόλιθος προέρχεται απο το αρχαίο ρήμα "Βρέμω". Ο ήχος των παφλασμών του κύματος στους βράχους</Box>
  </Dialog>

}

const MainMenu = withStyles(styles)(({props, classes, lang}) => {
  const [categories, setCategories] = useState([])
  const [openDialog,setOpenDialog] = useState(false)
  const [products, setProducts] = useState([])
  const [value, setValue] = React.useState(0);
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (lang === 'gr') {
      setCategories(greek.categories)
      setProducts(greek.products)
      setSelectedCategory(greek.categories[0])
    } else if (lang === 'en')
      setCategories(english.categories)
  }, [lang])

  return <>
    <Box className={classes.backgroundContainer}>
      <AppBar position="sticky" color="primary" className={classes.headerBar}>
        <Toolbar>
          <Box className={classes.socialIcon}>
            <Button color={'secondary'} onClick={() => setOpenDrawer(!openDrawer)}><Menu/></Button>
          </Box>
          <Grid container direction={'column'} alignItems={'center'} alignContent={'center'} justify={'center'}>
            <Grid item>
              <Box className={'logo'} width={'80px'} component={Button} href={'/'}>
                <img src={Logo} alt={'Logo'} width={'100%'}/>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6} className={classes.categoryRow}>
              <Typography variant={'h4'} color={'secondary'} align={'center'}>{selectedCategory.name}</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Container>
        <Box mt={2} pt={2}>
          <ProductsView products={products} category={selectedCategory}/>
        </Box>
        <AppBar position="fixed" color="primary" className={classes.appBar}>
          <Grid container alignContent={'center'} alignItems={'center'} justify={'center'}>
            <Grid item xs={12} sm={12} md={10} lg={4} xl={3}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
                classes={{indicator: classes.customIndicator}}
                aria-label="scrollable auto tabs example"  {...props}>
                {categories.map((category) => {
                  return <Tab key={category.id} className={classes.footerIcon} centerRipple
                              label={<FooterMenuButton id={category.icon}/>} onClick={() => {
                    setSelectedCategory(category)
                  }}/>
                })}
              </Tabs>
            </Grid>
          </Grid>
        </AppBar>
      </Container>
    </Box>
    {openDialog && <VromolithosPopup openDialog={setOpenDialog}/> }
    <SwipeableDrawer anchor={'right'} open={openDrawer} onClose={() => setOpenDrawer(false)}>
      <Box p={4} className={classes.drawerWidth}>
        <Grid container direction={'column'} spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h4'}>{BUSINESS_NAME}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Button href={'/menu/en'}><Flag code={'GB'} height={'16px'}/>
             <Box ml={2}><Typography variant={'h5'} color={'primary'}>English</Typography></Box>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} mb={2}>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h5'} color={'primary'}>Ήξερες ότι...</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h6'} onClick={()=>{
              setOpenDialog(true)
              setOpenDrawer(openDrawer)}}>Τι σημαίνει Βρομόλιθος;</Typography>
          </Grid>
        </Grid>
        <Box className={classes.footerSocial}>
          <Facebook/>
          <Instagram/>
        </Box>
      </Box>
    </SwipeableDrawer>
  </>
})


export default MainMenu