import React from 'react'
import {Switch} from 'react-router-dom'
import CustomRoute from 'components/core/CustomRoute'
import Menu from 'components/Menu'
import HomeMenu from 'components/Menu/HomeMenu'


const Routes = ({props}) => {
  return <Switch>
    <CustomRoute pageTitle = {''} path='/' component={Menu} exact/>
    <CustomRoute pageTitle={'Menu'} path='/menu/:lang' component={HomeMenu}/>
  </Switch>
}

export default Routes