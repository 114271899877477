import React, {useState} from 'react'
import {Box, Container, Grid} from '@material-ui/core'
import backgroundImage from 'assets/indexBack.jpg'
import popMenu from 'assets/popLogo.png'
import logo from 'assets/logo.png'
import Flag from 'react-world-flags'
import {withStyles} from '@material-ui/core/styles'
import {ThemeProvider} from '@material-ui/core/styles'
import {theme} from 'theme'
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  splashBackground: {
    background: `linear-gradient(0deg, rgba(38, 17, 17, 0.3), rgba(38, 17, 17, 0.3)), url(${backgroundImage}) no-repeat`,
    backgroundSize: 'cover',
    minHeight: window.innerHeight,
    height: 'auto',
  },
  logo: {
    width: '300px',
    height: '300px',
    borderRadius: '50%',
    border: '1px solid #ffffff'
  },
  popMenuLogo:{
    bottom:0
  }
})

const MenuPage = withStyles(styles)(({props, classes}) => {

  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <ThemeProvider theme={theme}>
    <Box className={classes.splashBackground}>
      <Container>
        <Grid container direction={'column'} justify={'center'} alignContent={'center'} alignItems={'center'}>
          <Grid item>
            <Box mt={8}>
              <img className={classes.logo} src={logo} alt={'logo'}/>
            </Box>
          </Grid>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={6}>
            <Box mt={10} width={'100%'}>
              <Button aria-controls="simple-menu" aria-haspopup="true" variant={'contained'} color={'secondary'}
                      fullWidth size={'large'} onClick={handleClick}>Language </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} href={'/menu/gr'} component={Button}><Flag code={'GR'} height={'20px'}/><Box
                  ml={2}>Greek</Box></MenuItem>
                <MenuItem onClick={handleClose} href={'/menu/en'} component={Button}><Flag code={'GB'} height={'16px'}/><Box
                  ml={2}>English</Box></MenuItem>
              </Menu>
              {/* TODO: Dynamic selection of Languages */}
              {/*<Grid container direction={'row'} justify={'center'} alignItems={'center'} spacing={2}>*/}
              {/*  <Grid item>*/}
              {/*    <Link to={'/menu/gr'}><Flag code={'GR'} height={'50px'}/></Link>*/}
              {/*  </Grid>*/}
              {/*  <Grid item>*/}
              {/*    <Flag code={'GR'} height={'50px'}/>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}
            </Box>
          </Grid>
          <Grid item>
            <Box mt={20}>
              <img src={popMenu} alt={'popMenu Logo'} width={100}/>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </ThemeProvider>
})

export default MenuPage