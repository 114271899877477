export const translate = {
  categories:
    [
      {name: 'Καφέδες',id:1,icon:1},
      {name: 'Αναψυκτικά',id:2,icon:2},
      {name: 'Κοκτέιλ',id:3,icon:3},
      {name: 'Σαλάτες',id:4,icon:4},
      {name: 'Σάντουιτς',id:5,icon:5},
      {name: 'Ποτήρι Ελληνικό ποτό',id:6,icon:6},
      {name: 'Σαμπάνιες',id:7,icon:7},
      {name: 'Οινοπνευματώδη',id:8,icon:8},
      {name: 'Μπύρες',id:9,icon:9},
    ],
  products:
    [
      {categoryId:1,productId:1,name:'Espresso',description:null,price:'2.00'},
      {categoryId:1,productId:2,name:'Espresso διπλό',description:null,price:'2.50'},
      {categoryId:1,productId:3,name:'Αμερικάνο',description:null,price:'2.50'},
      {categoryId:1,productId:4,name:'Καπουτσίνο',description:null,price:'3.00'},
      {categoryId:1,productId:5,name:'Φρέντο εσπρέσσο',description:null,price:'2.50'},
      {categoryId:1,productId:6,name:'Φρέντο Καπουτσίνο',description:null,price:'3.00'},
      {categoryId:1,productId:7,name:'Ελληνικός μονός',description:null,price:'2.00'},
      {categoryId:1,productId:8,name:'Ελληνικός διπλός',description:null,price:'2.50'},
      {categoryId:1,productId:9,name:'Φραπέ',description:null,price:'2.50'},
      {categoryId:1,productId:10,name:'Ιρλανδέζικο',description:null,price:'7.00'},
      {categoryId:1,productId:11,name:'Μακιάτο',description:null,price:'3.00'},
      {categoryId:2,productId:13,name:'Αναψυκτικά 330ml',description:null,price:'2.00'},
      {categoryId:2,productId:14,name:'Παγωμένο Τσάι',description:null,price:'3.00'},
      {categoryId:3,productId:15,name:'Απερόλ Σπρίτζ',description:null,price:'7.00'},
      {categoryId:3,productId:16,name:'Μοχίτο',description:null,price:'7.00'},
      {categoryId:3,productId:17,name:'Καϊπιρίνια',description:null,price:'7.00'},
      {categoryId:3,productId:18,name:'Μαργαρίτα',description:null,price:'7.00'},
      {categoryId:3,productId:19,name:'Ντάκιρι Φράουλα',description:null,price:'7.00'},
      {categoryId:3,productId:20,name:'Μπλάντι Μαίρη',description:null,price:'7.00'},
      {categoryId:3,productId:21,name:'Μπλάκ Ράσιαν',description:null,price:'7.00'},
      {categoryId:3,productId:22,name:'Κούμπα Λίμπρε',description:null,price:'7.00'},
      {categoryId:3,productId:23,name:'Καϊπιρόσκα',description:null,price:'7.00'},
      {categoryId:3,productId:24,name:'Παράδεισος Beach',description:null,price:'7.00'},
      {categoryId:4,productId:25,name:'Φρουτοσαλάτα με γιαούρτι',description:null,price:'7.00'},
      {categoryId:4,productId:26,name:'Φρουτοσαλάτα',description:null,price:'5.00'},
      {categoryId:4,productId:27,name:'Σαλάτα Παράδεισος',description:'Μαρούλι, Τυρόμπαλες απο ανθότυρο, Φυστίκι, Ντρέσιγκ Βινεγκρέτ',price:'8.00'},
      {categoryId:4,productId:28,name:'Σαλάτα Άννα',description:'Μαρούλι, Λιαστή ντομάτα, Κουκουνάρι, Φλούδες Παρμεζάνα, Βαλσάμικο',price:'8.00'},
      {categoryId:4,productId:29,name:'Σαλάτα Χωριάτικη',description:'Ντομάτα, Αγγούρι, Κρεμμύδι, Πιπεριά, Φέτα',price:'7.00'},
      {categoryId:4,productId:30,name:'Σαλάτα Σίζαρ',description:'Μαρούλι, Κρουτόν, Κοτόπουλο, Μαγιονέζα, Τσιγαρισμένο Μπέικον',price:'9.00'},
      {categoryId:4,productId:31,name:'Σαλάτα Λέρικη',description:'Ντομάτα, Παξιμάδι, Κάπαρη, Τσίτσιρι, Ρίγανη',price:'8.00'},
      {categoryId:5,productId:32,name:'Τορτίγια αλλαντικών',description:'Τυρί, ντοματίνι, ζαμπόν, μπέικον, μαγιονέζα',price:'3.50'},
      {categoryId:5,productId:33,name:'Τορτίγια κοτόπουλο',description:'Τυρί, ντοματίνι, κοτόπουλο, μπέικον, μαγιονέζα',price:'4.50'},
      {categoryId:5,productId:34,name:'Τορτίγια τονοσαλάτα',description:'Μαρούλι, ντοματίνι, τονοσαλάτα',price:'4.50'},
      {categoryId:5,productId:35,name:'Κλάμπ σάντουιτς',description:'Τυρί, ντοματίνι, μαγιονέζα, μπέικον, αυγό, ζαμπόν, πατάτες τηγανιτές',price:'6.00'},
      {categoryId:5,productId:36,name:'Σάντουιτς αλλαντικών',description:'Τυρί, ντοματίνι, ζαμπόν, μπέικον, μαγιονέζα',price:'3.50'},
      {categoryId:5,productId:37,name:'Σάντουιτς κοτόπουλο',description:'Τυρί, ντοματίνι, κοτόπουλο, μπέικον, μαγιονέζα',price:'4.50'},
      {categoryId:6,productId:38,name:'Ποτήρι Ούζο',description:null,price:'2.50'},
      {categoryId:6,productId:39,name:'Ποτήρι τσίπουρο',description:null,price:'2.50'},
      {categoryId:6,productId:40,name:'Ποτήρι λευκό, κόκκινο, ροζέ κρασί',description:null,price:'2.50'},
      {categoryId:6,productId:41,name:'Καραφάκι Ούζο 200ml',description:'Συνοδεύεται με μεζέ',price:'8.00'},
      {categoryId:6,productId:42,name:'Καραφάκι Τσίπουρο 200ml',description:'Συνοδεύεται με μεζέ',price:'8.00'},
      {categoryId:6,productId:43,name:'Καραφάκι Ούζο με θαλασσινά',description:'Για δυο άτομα',price:'20.00'},
      {categoryId:6,productId:44,name:'Καραφάκι Ούζο με αλλαντικά',description:'Για δυο άτομα',price:'17.00'},
      ]
}